import { admin2 } from '@/api/instance'

// => 同步所有商品
export const SyncAllRezioProduct = async ({ shopId }) => {
  return admin2({
    method: 'post',
    url: `/${shopId}/ecommerceRezioProduct/syncAllProduct`,
    data: {},
  })
}

// => 刪除特定產品
export const SyncOneRezioProduct = async ({ shopId, id }) => {
  return admin2({
    method: 'post',
    url: `/${shopId}/ecommerceRezioProduct/${id}/syncOneProduct`,
    data: {},
  })
}
